<titleimage [title]="'Partners'">
</titleimage>
<main class="container">
    <div class="col-md-12">
        <p>
            <strong>
                Nausys
            </strong>
        </p>
        <p>
            NauSYS™ is a modern and complete information solution for monitoring and optimising business processes for your charter company.
        </p>
        <p>
            It is the first croatian online booking system based on a web application concept. The system is specialized for companies which are focused on cost optimisation and revenue growth. In the past years, NauSYS™ became the most relevant system on the market
            and reached a respectable number of charter companies which are now using NauSYS™ as their primary booking system.&nbsp;
            <br/> NauSYS™ currently has more than&nbsp;2500 real-time available yachts&nbsp;and more than&nbsp;3000 charter agencies&nbsp;registered.
        </p>
        <p>
            The efficiency of NauSYS™ agency portal is remarkable! More than 50% of all agency bookings are made directly and free of charge on the agency portal. That gives a great advantage to charter companies which are using NauSYS™.&nbsp;
            <br/> A different approach to our clients and full support is awarded with loyality. A great foundation for long term cooperation
        </p>
        <br/>
        <p>
            <strong>
                Sea Independent
            </strong>
        </p>
        <p>
            Sea Independent is een jachtmakelaardij die wereldwijd actief is op 36 locaties en die gespecialiseerd is in de verkoop en bemiddeling van luxe zeiljachten en motorjachten, in de klasse van 14 tot 50 meter, zowel nieuw als gebruikt.
        </p>
        <p>
            Sea Independent bestaat uit een wereldwijd netwerk van meer dan dertig internationaal gecertificeerde makelaars in jachten. Het heeft uitgebreide expertise in huis en beschikt over veelzijdige technische, financiële en juridische kennis.
        </p>
        <br/>
        <p>
            <strong>
                Pantaenius
            </strong>
        </p>

        <span>Skipper's liability insurance</span>
        <p>Skipper's liability insurance covers damage that is caused to third parties through the use of the chartered motor or sailing yacht and tender by the skipper and crew. Included in the insured risks are damage to other ships and to harbour or jetty
            installations. Damage arising from gross negligence on the chartered ship and not covered by the lessor's hull insurance is also included. Also included are cover for cancellation of charter, confiscation, hotel and transport costs and financial
            loss.
        </p>

        <span>Trip Cancellation Costs Insurance</span>
        <p>Return journey cost insurance reimburses any contractually payable charter cancellation costs and outward and return travel costs arising from the non-availability of a non-commercial skipper or of one or more crew members, or, if the journey
            is abandoned after commencement, any additionally incurred return journey costs and the relevant portion of the charter costs.</p>

        <span>
Travel Health Insurance</span>
        <p>In addition to costs of medical treatment, medication and admission to hospital, foreign travel health insurance reimburses any return ambulance transport to the home country or repatriation costs in case of death.</p>

        <span>Personal Accident Insurance</span>
        <p>The accident insurance in our charter package provides cover in cases of death and invalidity, including a number of aspects that are not protected by general accident insurance. Skipper and crew are insured during private use of the chartered
            yacht, tenders and watersports equipment and for trips ashore of up to 48 hours.</p>

        <span>Bond Insurance
</span>
        <p>Bond insurance covers the financial risk in cases where the lessor retains the bond provided, in full or in part –whether or not this is justified.</p>
        
        <a href="http://www.pantaenius-group.com/content/2886" target="_blank">Bekijk hier het Charterpaket</a>
        <br/>
        <br/>
        <p>
            <strong>
                E.M.C.I
            </strong>
        </p>
        <p>
            EMCI Register certificeert wereldwijd maritieme practitioners (maritieme arbiters, experts, havenmeesters, makelaars, mediators, taxateurs) &amp; hun bedrijven en is100%&nbsp;onafhankelijk.
        </p>
        <p>
            EMCI maritieme practitioners voldoen aan de vereisten voor permanente opleiding, hebben EMCI gedragsregels en vallen onder EMCI klacht- &amp; tuchtrecht.
        </p>
        <p>
            EMCI Register is&nbsp;ISO 9001:2008 gecertificeerd,onderdeel van Global Network Group en onder supervisie van ICC Council.
        </p>
    </div>
</main>
