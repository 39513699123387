<titleimage [title]="'Island Hopping'">
</titleimage>

<section>
    <div class="container">
        <div class='row'>
            <h1 class='center'>
                Nieuw in {{ currentyear }} bij Sailpoint Kroatië
            </h1>'
            <div class="divider text-center big ">
                <span style="background-color:#f1f1f1;">
                </span>
            </div>
            <div class="space10"></div>
            <h2 class='center'> Island hopping op één van de ruim 60 verschillende boten van Katarina Cruises</h2>
            <div class='space30'></div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <h1>Van eiland naar eiland zoals jij dat wilt</h1>
                <div class="space20"></div>

                <h2>Land cruise programma’s</h2>
                <p>Combineer de schoonheid van de Adriatische zee met de cultuur en authenticiteit van de eilanden, geniet van één van de verschillende land cruise programma’s door relaxed van eiland naar eiland te ‘hoppen’ op een comfortabele boot met bemanning.</p>
                <h2>Croatia Rhapsody coach tour</h2>
                <p>Eén van onze favoriete tours, die 5 UNESCO steden van Kroatië aandoet. Tijdens deze cruise geniet je van verschillende wijnproeverijen en heerlijke lokale maaltijden in bijzondere plaatsen met een rijke geschiedenis. Je overnacht in 4-sterren
                    hotels. Vertrek: iedere zondag in de zomermaanden vanuit Dubrovnik.</p>
                <h2>Young fun cruises</h2>
                <p>Ook voor de jongeren en studenten hebben we een geweldig aanbod waarbij Hot-spots, Jet-set eilanden, festivals en events worden bezocht.</p>
                <h2>Charters corporate groepen</h2>
                <p>met een vloot van meer dan 60 boten in 6 verschillende categorieën, kunnen wij je een maatwerk programma bieden, zoals jij dat wilt!</p>
                <p>Voor meer informatie over deze bijzondere Island hopping cruises, neem contact op met Alen Cehic, Sailpoint Kroatië, tel +31642143783 of via e-mail <a href="mailto:Info@Sailpoint.nl">Info@Sailpoint.nl</a></p>

                <div id="slider" class="slider flexslider">
                    <ul class="slides">
                        <li *ngFor="let img of sliderpics">
                            <a href='{{ img | cd_b:"w_auto,h_800" }}' data-lightbox="custom">
                            <img src='{{ img | cd_b:"w_auto,h_800" }}' />
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="widget clearfix">
                    <h2>Beleef Kroatie met Sailpoint & Katarina Cruises</h2>
                    <iframe width="358" height="200" src="https://www.youtube.com/embed/qHz5I2VQ0bc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div class="widget clearfix">
                    <h2>Contact & Vrijblijvende Offerte</h2>
                    <form ngNoForm action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h" method="post" target="_blank">
                        <input type=" text " name="Aanvraag Type: " style="display: none " value='Offerte Island Hopping' />
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Naam" name="naam" ngModel required/>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" name="email" ngModel required/>
                        </div>
                        <div class="input-group form-group widget-date">
                            <!--<span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>-->
                            <p-calendar class="left" placeholder="Van" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.start" [disabledDays]="[0,1,2,3,4,5]" [maxDate]="this.filter.end" (onSelect)="onSelectS($event)"></p-calendar>
                            <p-calendar class="right" placeholder="Tot" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="this.filter.start" (onSelect)="onSelectE($event)">
                            </p-calendar>
                        </div>

                        <div class="form-group">
                            <textarea type="text" class="form-control" placeholder="Bericht" name="bericht" rows="5" ngModel required>
                            </textarea>
                        </div>
                        <input type=" text " name="Vanaf " style="display: none " value='{{filter.start}}' />
                        <input type="text " name="Tot " style="display: none " value='{{filter.end}}' />
                        <div class="form-group">
                            <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn bg_green button-primary btn btn-default btn-primary">
                            Stuur
                        </button>
                        </div>
                        <input type="text" name="__bucket_trap__" value="" style="display: none" />
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>
