<section style="margin-top:-15px" class="section marginbottom30">
    <div id="mapid" style="height:244px;">
    </div>
</section>
<section class="section marginbottom20">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 padding0 destoverlay">
                <div class="section row border_grey bg_grey destinner">
                    <div class="col-md-4 padding0">
                        <div class="margin50 archive1">
                            <div id="nausys_simpleweather_widget"></div>
                        </div>
                    </div>
                    <div class="col-md-4 padding0">
                        <div class="margin50 archive1" (click)="toBoats()">
                            <a class="btn_icon bg_orange extrabig square white absolute">
                                <i class="fa fa-anchor">
                                </i>
                            </a>
                            <div class="float_left marginleft100">
                                <h4>
                                    Zeilen in {{ location?.title }}
                                </h4>
                                <div class="space20">
                                </div>
                                <p>
                                    Bekijk alle zeiljachten in de haven van {{ location?.title }}
                                </p>
                                <div class="space20">
                                </div>
                                <div class="space5">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let l of location?.locationtip;" class="col-md-4 padding0">
                        <div class="margin50 archive1">
                            <a class="btn_icon {{ l.color }} extrabig square white absolute">
                                <i class="fa {{ l.icon }}">
                                </i>
                            </a>
                            <div class="float_left marginleft100">
                                <h4>
                                    {{ l.title }}
                                </h4>
                                <div class="space20">
                                </div>
                                <p>
                                    {{ l.subtitle }}
                                </p>
                                <div class="space20">
                                </div>
                                <div class="space5">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 margintop20 disable_marginleftright_iphonpotr">
                <div class="plaatsnaam">
                    <h2>
                        <span>
                            {{ location?.title }}
                        </span>
                    </h2>
                </div>
                <div class="space20">
                </div>
                <div class="cloud">
                    <div class="focus bg_blue">
                        <div class="margin20">
                            <p class="white">
                                "{{ location?.aleninfo }}"
                            </p>
                        </div>
                    </div>
                    <div class="focus">
                        <div class="triangle marginleft20">
                        </div>
                        <div class="space30">
                        </div>
                        <div class="focus relative">
                            <img alt="" class="radius_circle absolute" width="65" height="65" src="../assets/plaats/alen.jpg" />
                            <div class="activity marginleft85">
                                <a href="/over_ons">
                                    <h5>
                                        ALEN ČEHIĆ
                                    </h5>
                                </a>
                                <div class="space10">
                                </div>
                                <p>
                                    Sailpoint Kroatië
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space20">
                </div>
            </div>
            <div class="col-sm-8 margintop20 disable_marginleftright_iphonpotr">
                <h2>
                    Over de plaats
                </h2>
                <p *ngFor="let p of location?.overplaats" [innerHTML]="p.text">
                </p>
            </div>
        </div>
    </div>
    <div class="divider padding0 margin200">
        <div class="container">
            <div id="flexslider-1" class="flexslider">
                <ul class="slides">
                    <li *ngFor="let pic of location?.pictures">
                        {{ pic.url }}
                        <img src='{{ pic.url | cd_b:"w_1200,h_300,c_fill" }}' alt="{{ pic.alt }}" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="container">
            <div class="row marginbottom20">
                <div class="col-md-12">
                    <div class="field-item even">
                        <ng-container *ngFor="let p of location?.description">
                            <p>
                                <strong>
                                    {{ p.title }}
                                </strong>
                            </p>
                            <p [innerHTML]="p.text">
                            </p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row" id='harbourboats'>
                <h3 class="center marginbottom20">
                    Boten in {{ location?.title }}
                </h3>
                <div *ngIf="boats?.length == 0" class="text-center">
                    <i>
                                Wij bieden momenteel geen boten aan in {{ location?.title }} via onze website. U kunt altijd persoonlijk contact opnemen met ons en vragen naar de mogelijkheiden om toch te zeilen vanuit {{ location?.title }}.
                            </i>
                </div>
            </div>
            <div class="row marginbottom20">
                <div class="grid-wrapper">
                    <ng-container *ngFor="let boat of boats">
                        <div class="textevidence bg_greydark center">
                            <a routerLink="/boot/{{ boat.id }}">
                                <h4 class="white margin20">
                                    {{ boat.yachtModel }}
                                </h4>
                            </a>
                        </div>
                        <a routerLink="/boot/{{ boat.id }}" class="fadeinout"><img class="width_percentage100 focus zoom_image" typeof="foaf:Image" src='{{ boat.mainPictureUrl | cd:"w_280,h_280,c_fill" }}' alt="" /></a>
                        <div class="textevidence bg_orange center">
                            <a routerLink="/boot/{{ boat.id }}">
                                <h5 class="white margin20">
                                    {{ boat.name }}
                                </h5>
                                <i class="fa fa-anchor iconbg orange"></i>
                            </a>
                        </div>
                    </ng-container>

                </div>
            </div>
            <div *ngIf="boats?.length != 0" class="row text-center">
                <a (click)="redirectBoats()" class=" btn more bg_yellow small square white">
                    Bekijk alle boten in {{ location?.title }}
                </a>
            </div>
        </div>
    </div>
</section>
