<titleimage [title]="'Crewed Yachts'">
</titleimage>

<section>
    <div class="container">
        <div class='row'>
            <h1 class='center'>
                Crewed Yachts
            </h1>
            <div class="space20"></div>
            <div class="divider text-center big ">
                <span style="background-color:#f1f1f1;">
                </span>
            </div>
            <div class="space10"></div>
            <h2 class='center'> Superieur zeil-plezier in Kroatië met crew</h2>
            <div class='space20'></div>
            <p class='center width_percentage80' style="margin: 0 auto">Wil je superieur zeil-plezier in Kroatië met een groter, luxer zeiljacht, met nog betere zeil- eigenschappen, meer comfort en ruimte? Naast ons standaard aanbod op de site, bieden wij een aantal nieuwe, zeer mooie, bijzondere boten aan waarmee jouw vakantie een nog onvergetelijkere ervaring kan worden. Een aantal van deze Special Boats zijn ‘bareboat’ te huur, anderen alleen met schipper. Uiteraard kun je, indien gewenst, iedere boot ook met een schipper, of zelfs met hostess en/of kok, huren.</p>
            <div class='space20'></div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <h1>Crewed Yachts</h1>
                <div class="space10"></div>
                <p>Indien u een bemanning inhuurt, bepaalt u in overleg met hen het programma. Waar u naar toe wilt, of u lange afstanden wilt varen, wilt zeilen of op de motor wilt varen, zwemmen, snorkelen, etc. </p>
                <p>U bezichtigt de mooiste plekken in de Adriatische zee, zwemt in de blauwe wateren in baaien bij de eilanden en bezoekt pittoreske dorpen en stadjes. De bemanning is zeer bekend met het gebied en zal u kundig adviseren. Routes en overnachting plaatsen zijn onder voorbehoud en afhankelijk van het weer.</p>
                <p>Kiest u voor een kok aan boord? Hij verzorgt de beste gerechten met gebruikmaking van lokale ingrediënten en bijpassende wijnen uit de regio. Uiteraard wordt hierbij ook rekening gehouden met uw persoonlijke wensen!</p>
                <p>Voor, tijdens en na de reis is Sailpoint Kroatië volledig betrokken bij de organisatie en management rondom uw vakantie.</p>
                <p>Voor meer informatie over deze bijzondere Special Boats, neem contact op met Alen Cehic, Sailpoint Kroatië, tel +31642143783 of via e-mail <a href="mailto:Info@Sailpoint.nl">Info@Sailpoint.nl</a></p>
            </div>
            <div class="col-sm-4">
                <!--<div class="widget clearfix">-->
                <!--    <h2>Beleef Kroatie met Sailpoint & Katarina Cruises</h2>-->
                <!--    <iframe width="358" height="200" src="https://www.youtube.com/embed/qHz5I2VQ0bc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <!--</div>-->

                <div class="widget clearfix">
                    <h2>Contact & Vrijblijvende Offerte</h2>
                    <form ngNoForm action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h" method="post" target="_blank">
                        <input type=" text " name="Aanvraag Type: " style="display: none " value='Offerte Crewed Yacht' />
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Naam" name="naam" ngModel required/>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" name="email" ngModel required/>
                        </div>
                        <div class="input-group form-group widget-date">
                            <!--<span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>-->
                            <p-calendar class="left" placeholder="Van" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.start" [disabledDays]="[0,1,2,3,4,5]" [maxDate]="this.filter.end" (onSelect)="onSelectS($event)"></p-calendar>
                            <p-calendar class="right" placeholder="Tot" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="this.filter.start" (onSelect)="onSelectE($event)">
                            </p-calendar>
                        </div>
     
                        <div class="form-group">
                            <textarea type="text" class="form-control" placeholder="Bericht" name="bericht" rows="5" ngModel required>
                            </textarea>
                        </div>
                        <input type=" text " name="Vanaf " style="display: none " value='{{filter.start}}' />
                        <input type="text " name="Tot " style="display: none " value='{{filter.end}}' />
                        <div class="form-group">
                            <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn bg_green button-primary btn btn-default btn-primary">
                            Stuur
                        </button>
                        </div>
                        <input type="text" name="__bucket_trap__" value="" style="display: none" />
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>
