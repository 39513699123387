<div class="section ">
    <div class="space30">
    </div>
    <div>
        <div *ngFor="let boat of boats;">
            <div class="col-md-3 col-sm-6 col-xs-6 padding0">
                <a routerLink="/boot/{{ boat.id }}">
                    <div class="focus sizing relative fadeinout overflow">
                        <img class="focus zoom_image2" src='{{ boat.mainPictureUrl | cd:"w_375,h_202,c_fill" }}' />
                        <div class="fadeout filter absolute height100percentage width_percentage100">
                            <div class="absolute display_table height100percentage width_percentage100">
                                <div class="cell vertical_middle">
                                    <p>
                                        <span class="btn white large green_hover">
                                            {{ boat.name }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="fadein filter black2 absolute height100percentage width_percentage100">
                            <div class="absolute display_table height100percentage width_percentage100">
                                <div class="cell vertical_middle">
                                    <p class="white delay top heavy">
                                        Zeil een
                                        <span class="green_hover">
                                            {{ boat.yachtModel }}
                                        </span>
                                    </p>
                                    <div class="space5">
                                    </div>
                                    <p>
                                        <span class="btn white large green_hover">
                                            {{ boat.name }}
                                        </span>
                                    </p>
                                    <div class="space5">
                                    </div>
                                    <p class="white delay bottom">
                                        {{ boat.location }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
