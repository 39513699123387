<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-xs-6" *ngFor="let p of partners;">
                <div class="outer">
                    <div class="left width_percentage40">
                        <div class="inner">
                            <img alt="" src="{{ p.img }}" />
                        </div>
                    </div>
                    <div class="right width_percentage60">
                        <h4 class="white">
                            {{ p.title }}
                        </h4>
                        <div class="divider">
                            <span>
                    </span>
                        </div>
                        <p class="white marginbottom10">
                            {{ p.subtitle }}
                        </p>
                        <a routerLink="/partners">
                    Lees Meer
                </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
