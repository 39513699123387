<main class="container">
    <div class="col-md-9">
        <p>
            <strong>
                Charter- en leveringsvoorwaarden Sailpoint Kroatië
            </strong>
        </p>
        <p>
            Bij de bemiddelde jachten treedt Sailpoint Kroatië slechts op als bemiddelaar/agent in een overeenkomst tussen de huurder en de, altijd in de overeenkomst genoemde, verhuurder.
        </p>
        <p>
            Er gelden eveneens algemene voorwaarden van de verhuurder, die een onderdeel vormen van de overeenkomst tussen huurder en verhuurder. Deze algemene voorwaarden worden op verzoek vooraf toegezonden en zullen altijd bijgeleverd worden bij de overeenkomst. De algemene voorwaarden van Sailpoint Kroatië vormen een aanvulling op de algemene voorwaarden van de verhuurder. Daar waar deze algemene voorwaarden tegenstrijdig zijn gelden de algemene voorwaarden van Sailpoint Kroatië.
        </p>
        <p>
            <strong>
                Betalingsvoorwaarden:
            </strong>
        </p>
        <ul>
            <li>
                Alle vermelde prijzen zijn in Euro's.
            </li>
            <li>
                De overeenkomst komt tot stand na het ondertekend retour sturen van de offerte door de huurder en/of betaling van de 1
                <sup>
                    e
                </sup>
                &nbsp;factuur door de huurder.
            </li>
            <li>
                Bij boeking dient direct na het ontvangen van de factuur 50% van de prijs te worden aanbetaald. De resterende 50% dient uiterlijk 6 weken voor vertrek te zijn voldaan. Bij een niet tijdige betaling is Sailpoint Kroatië gerechtigd om, zonder verdere vooraankondiging, over te gaan tot annulering van de reis bij de verhuurder.
            </li>
            <li>
                Huurder heeft de mogelijkheid tot annuleren van de door hem geboekte charter met inachtneming van de volgende regels:
                <ul>
                    <li>
                        Huurder is de volgende bedragen verschuldigd:
                        <ul>
                            <li>
                                tot 6 weken voor vertrek 50% van de prijs.
                            </li>
                            <li>
                                binnen 6 weken voor vertrek 100% van de prijs.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Annulering dient schriftelijk te geschieden door middel van een aangetekend schrijven, gericht aan Sailpoint Kroatië.&nbsp; De datum "voor ontvangst getekend" is bepalend.
                    </li>
                    <li>
                        Huurder mag een vervanger voor de charter aanstellen, mits deze eveneens beschikt over de vereiste diploma's/certificaten.
                    </li>
                </ul>
            </li>
            <li>
                Extra's die niet inclusief de charterprijs zitten, dienen z.s.m. na boeking, doch uiterlijk 4 weken voor aanvang van de verhuurperiode aan verhuurder te worden doorgegeven.&nbsp;
            </li>
            <li>
                Een reis- en ongevallenverzekering, als ook een annuleringsverzekering wordt aanbevolen.
            </li>
        </ul>
        <p>
            <strong>
                Aansprakelijkheid&nbsp;
            </strong>
        </p>
        <ul>
            <li>
                Huurder is aansprakelijk voor tijdens de huurperiode ontstane schade aan het jacht, voor zover deze schade niet onder de verantwoordelijkheid valt van de verhuurder, zoals slijtage, ouderdom, gebreken in het onderhoud e.d. Deze aansprakelijkheid van de huurder is beperkt tot het bedrag van de borgsom m.u.v. nalatigheid of grove schuld. Sailpoint Kroatië aanvaardt geen aansprakelijkheid voor schade of derving van reisgenot van de zijde van huurder in genoemde situaties.&nbsp;
            </li>
            <li>
                Sailpoint Kroatië kan uitdrukkelijk niet aansprakelijk worden gesteld voor het in gebreke blijven van de verhuurder, ook wanneer verhuurder door financieel onvermogen niet in staat is om aan de uit de overeenkomst voortvloeiende tegenprestatie te voldoen. Sailpoint Kroatië zal alle mogelijke inspanning verrichten om bij wanprestatie van de verhuurder te zoeken naar alternatieven.
            </li>
            <li>
                Sailpoint Kroatië zal alle financiële verplichtingen richting verhuurder namens huurder uitvoeren en hierover ten allen tijden verantwoording af kunnen leggen.
            </li>
        </ul>
        <p>
            <strong>
                Certificaten
            </strong>
        </p>
        <ul>
            <li>
                Huurder dient in het bezit te zijn van de benodigde certificaten die door de autoriteiten van Kroatië verplicht zijn gesteld. Bij het niet beschikken over de benodigde certificaten c.q. ervaring kan de verhuurder de huurder verplichten een schipper in te huren.
            </li>
            <li>
                In Kroatië is per boot een vaarbewijs I of een "ICC coastal waters" noodzakelijk. Vaarbewijs II wordt aanbevolen.
            </li>
            <li>
                Tevens dient minimaal één opvarende in het bezit te zijn van een bedieningscertificaat Marifonie.&nbsp;
            </li>
        </ul>
        <p>
            Onze aanbiedingen zijn altijd vrijblijvend, niet bindend en onder voorbehoud van beschikbaarheid en door ons niet gekende typfouten.
        </p>
    </div>
    <div class="col-md-3">
        <h2>
            Download als pdf
        </h2>
        <a href="/assets/static-pages/sailpoint_algemene_voorwaarden.pdf">
            Sailpoint_algemene_voorwaarden.pdf
        </a>
    </div>
</main>
