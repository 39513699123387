<section class="section marginbottom30" [ngStyle]="{'background': 'url(' + image + ') 50% 50% / cover'}" style="margin-top: -15px;">
    <div class="filter greydark">
        <div class="container clearfix">
            <div class="space90">
            </div>
            <h1 class="center white subtitle">
                {{ title }}
            </h1>
            <div class="space30">
            </div>
            <div class="divider center big">
                <span class="bg_white">
                </span>
            </div>
            <div class="space90">
            </div>
        </div>
    </div>
</section>
