<header>
    <nav class="navbar navbar-fixed-top">
        <div class="topbar">
            <div class="navbar-header">
                <button aria-controls="navbar" aria-expanded="false" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed">
                    <span class="white float_left">
                        Menu
                    </span>
                    <div class="float_left menu_bar">
                        <span class="icon-bar bg_white">
                        </span>
                        <span class="icon-bar bg_white">
                        </span>
                        <span class="icon-bar bg_white">
                        </span>
                    </div>
                </button>
            </div>
            <div class="container">
                <div class="row space30 remove">
                    <a class="header-left" routerLink="/">
                        <img src="assets/logo.png"/>
                        <span class="header-title">
                            Sailpoint Kroatië
                        </span>
                    </a>
                </div>
                <div class="navbar-collapse collapse" id="navbar" [collapse]="isCollapsed">
                    <ul class="menu nav navbar-nav navbar-right" (click)="isCollapsed = !isCollapsed">
                        <div>
                            <li>
                                <a class="main_menu_home" routerLink="/">
                                    Home
                                </a>
                            </li>
                            <li *ngFor="let link of main_menu">
                                <a routerLink="/{{ link.path }}" routerLinkActive="active">
                                    {{ link.label }}
                                </a>
                            </li>
                        </div>
                        <div class="display_mobile">
                            <li *ngFor="let link of secondary_menu">
                                <a routerLink="/{{ link.path }}" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }">
                                    {{ link.label }}
                                </a>
                            </li>
                        </div>
                    </ul>
                    <div class="space20 remove">
                    </div>
                </div>
            </div>
        </div>
        <div class="bottombar bottombarremove">
            <div class="container">
                <!-- <a class="offerte" routerlink="/contact" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }">
                    Vraag een vrijblijfende offerte aan!
                </a>-->
                <ul>
                    <li>
                        <a (click)="ofModal.show()" id="openModal">Vrijblijfende offerte</a>
                    </li>
                    <li *ngFor="let link of secondary_menu">
                        <a routerLink="/{{ link.path }}" routerLinkActive="active" routerLinkActiveOptions="{ exact: true }">
                            {{ link.label }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>

<div bsModal #ofModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    Reis op maat
                </h4>
                <button type="button" class="close" (click)="ofModal.hide()" aria-label="Close">
                        <i aria-hidden="true" class="fa fa-close">
                        </i>
                    </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 text-center margin200">
                        <span>Sailpoint Kroatië is naast de ideale plek voor bareboat huren, ook dé plek om een volledige zeilreis te boeken. Door onze kennis, ervaring en liefde voor Kroatië is Sailpoint in staat een geheel verzorgde zeilvakantie voor u en uw medereizigers te organiseren. Vlucht, zeiljacht, havenplaatsen en schipper, alles kan worden geregeld. Op die manier kunt u optimaal genieten van een heerlijke vakantie!</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <h2>
                            Uw gegevens
                        </h2>
                        <form ngNoForm id="demo-form" action="https://api.formbucket.com/f/buk_bZUUVXHQsSq8NmRih9BZTBkN" method="post" target="_blank">
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-address-card-o fa-fw"></i></span>
                                <input class="name border_grey" placeholder="Naam" type="text" name="naam" required />
                            </div>
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-envelope-o fa-fw"></i></span>
                                <input class="email border_grey" placeholder="Email" type="email" name="email" required />
                            </div>
                            <div class=" input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-phone fa-fw"></i></span>
                                <input placeholder="Telefoon" class="border_grey" type="text" name="telefoon" required />
                            </div>
                            <div class="input-group form-group" style="background-color: #f1f1f1;">
                                <span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>
                                <!--<material-datepicker class="date left" placeholder="Van" accentColor="#1bbc9b" dateFormat="DD-MM-YYYY" [(date)]="this.filter.start" (onSelect)="onSelectS($event)">-->
                                <!--</material-datepicker>-->
                                <!--<material-datepicker class="date right" placeholder="Tot" dateFormat="DD-MM-YYYY" [(date)]="this.filter.end" (onSelect)="onSelectE($event)">-->
                                <!--</material-datepicker>-->
                                <p-calendar class="date left" placeholder="Van" dateFormat="dd-mm-yy" [(ngModel)]="filter.start" [disabledDays]="[0,1,2,3,4,5]" [maxDate]="filter.end" (onSelect)="onSelectS($event)"></p-calendar>
                                <p-calendar class="date right" placeholder="Tot" dateFormat="dd-mm-yy" [(ngModel)]="filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="filter.start" (onSelect)="onSelectE($event)">
                                </p-calendar>
                            </div>
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-file-text-o fa-fw"></i></span>
                                <textarea placeholder="Beschrijf ons uw ideale zeilvakantie" class="border_grey" name="bericht" rows="5" required></textarea>
                            </div>
                            <div class="float_left width_percentage100 marginbottom10">
                                <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                            </div>
                            <div class="form-actions">
                                <button class="button-primary form-submit btn btn-default btn-primary" id="submit">
                                        Verstuur
                                    </button>
                            </div>
                            <input type=" text " name="Vanaf " value='{{filter.start}}' style="display: none " />
                            <input type="text " name="Tot " value='{{filter.end}}' style="display: none " />
                            <input type="text " name="__bucket_trap__ " value=" " style="display: none " />
                        </form>
                    </div>
                    <div class="col-md-4 ">
                        <div class="focus bg_blue">
                            <div class="margin20">
                                <p class="white">
                                    “Ik deel graag mijn kennis en ervaring over zeilen in Kroatië met u in een persoonlijk adviesgesprek”
                                </p>
                            </div>
                        </div>
                        <div class="focus">
                            <div class="triangle marginleft20">
                            </div>
                            <div class="space30">
                            </div>
                            <div class="focus relative">
                                <img alt="" class="radius_circle absolute" width="65" height="65" src="../assets/plaats/alen.jpg" />
                                <div class="activity marginleft85">
                                    <a href="/over_ons">
                                        <h5>
                                            ALEN ČEHIĆ
                                        </h5>
                                    </a>
                                    <div class="space10">
                                    </div>
                                    <p>
                                        Sailpoint Kroatië
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

