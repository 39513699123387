<div class="fullwidthbanner-container" style="margin-top: -15px;">
    <div class="container">
        <div class="row marginbottom20 margintop20">
            <a class="col-md-3" id="img1" (click)="ofModal.show()">
                <img alt="" class="focus" width="400" src="../../../../assets/static-pages/frontpage/boeg.jpg" />
                <div class="button-cont display_table absolute width_percentage100">
                    <div class="cell vertical_middle">
                        <a class="btn border_white white medium" (click)="ofModal.show()">
                            Boot Huren
                        </a>
                    </div>
                </div>
            </a>
            <a class="col-md-3" *ngFor="let pic of pics;" id="{{ pic.id }}" routerLink='{{ pic.url }}'>
                <img alt="" class="focus" width="400" src="{{pic.img}}" />
                <div class="button-cont display_table absolute width_percentage100">
                    <div class="cell vertical_middle">
                        <a class="btn border_white white medium" routerLink='{{ pic.url }}'>
                            {{ pic.name }}
                        </a>
                    </div>
                </div>
            </a>
            <a class="col-md-3" id="img1" href="https://www.farmhousecubani.com/" target="_blank">
                <img alt="" class="focus" width="400" src="../../../../assets/static-pages/frontpage/cubani_2.jpeg" />
                <div class="button-cont display_table absolute width_percentage100">
                    <div class="cell vertical_middle">
                        <a class="btn border_white white medium" href="https://www.farmhousecubani.com/"
                            target="_blank">
                            Onze B&B
                        </a>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <!--
    <div class=" section marginbottom10">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-6">
                                        <button type="button" (click)="ofModal.show()" id="openModal"
                                            class="btn left padding10 large margin0 width_percentage100">
                                            Vrijblijvende Bareboat offerte
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button type="button" (click)="maatModal.show()" id="openModal"
                                            class="btn left padding10 large margin0 width_percentage100">
                                            Zeilvakantie op Maat
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div> -->

    <!--    
    <front-search class="margintop10">
    </front-search>
    -->

</div>

<!-- END SLIDER CONTAINER WRAPPER -->
<div id="mobile-image">
    <p class="white">Sailpoint</p>
    <p class="green">Kroatië</p>
    <p class="white">.nl</p>
</div>

<div bsModal #ofModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    Vrijblijvende offerte
                </h4>
                <button type="button" class="close" (click)="ofModal.hide()" aria-label="Close">
                    <i aria-hidden="true" class="fa fa-close">
                    </i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-8">
                        <h2>
                            Uw gegevens
                        </h2>
                        <form ngNoForm id="demo-form" action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h"
                            method="post" target="_blank">
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-address-card-o fa-fw"></i></span>
                                <input class="name border_grey" placeholder="Naam" type="text" name="naam" required />
                            </div>
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-envelope-o fa-fw"></i></span>
                                <input class="email border_grey" placeholder="Email" type="email" name="email"
                                    required />
                            </div>
                            <div class=" input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-phone fa-fw"></i></span>
                                <input placeholder="Telefoon" class="border_grey" type="text" name="telefoon"
                                    required />
                            </div>
                            <div class="input-group form-group" style="background-color: #f1f1f1;">
                                <span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>
                                <!--<material-datepicker class="date left" placeholder="Van" accentColor="#1bbc9b" dateFormat="DD-MM-YYYY" [(date)]="this.filter.start" (onSelect)="onSelectS($event)">-->
                                <!--</material-datepicker>-->
                                <!--<material-datepicker class="date right" placeholder="Tot" dateFormat="DD-MM-YYYY" [(date)]="this.filter.end" (onSelect)="onSelectE($event)">-->
                                <!--</material-datepicker>-->
                                <p-calendar class="date left" placeholder="Van" dateFormat="dd-mm-yy"
                                    [(ngModel)]="filter.start" [disabledDays]="[0,1,2,3,4,5]" [maxDate]="filter.end"
                                    (onSelect)="onSelectS($event)"></p-calendar>
                                <p-calendar class="date right" placeholder="Tot" dateFormat="dd-mm-yy"
                                    [(ngModel)]="filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="filter.start"
                                    (onSelect)="onSelectE($event)">
                                </p-calendar>
                            </div>
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-file-text-o fa-fw"></i></span>
                                <textarea placeholder="Opmerkingen" class="border_grey" name="bericht" rows="5"
                                    required></textarea>
                            </div>
                            <div class="float_left width_percentage100 marginbottom10">
                                <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                            </div>
                            <div class="form-actions">
                                <button class="button-primary form-submit btn btn-default btn-primary" id="submit">
                                    Verstuur
                                </button>
                            </div>
                            <input type=" text " name="Vanaf " value='{{filter.start}}' style="display: none " />
                            <input type="text " name="Tot " value='{{filter.end}}' style="display: none " />
                            <input type="text " name="__bucket_trap__ " value=" " style="display: none " />
                        </form>
                    </div>
                    <div class="col-md-4 ">
                        <div class="focus bg_blue">
                            <div class="margin20">
                                <p class="white">
                                    “Ik deel graag mijn kennis en ervaring over zeilen in Kroatië met u
                                    in een
                                    persoonlijk adviesgesprek”
                                </p>
                            </div>
                        </div>
                        <div class="focus">
                            <div class="triangle marginleft20">
                            </div>
                            <div class="space30">
                            </div>
                            <div class="focus relative">
                                <img alt="" class="radius_circle absolute" width="65" height="65"
                                    src="../assets/plaats/alen.jpg" />
                                <div class="activity marginleft85">
                                    <a href="/over_ons">
                                        <h5>
                                            ALEN ČEHIĆ
                                        </h5>
                                    </a>
                                    <div class="space10">
                                    </div>
                                    <p>
                                        Sailpoint Kroatië
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #maatModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    Reis op maat
                </h4>
                <button type="button" class="close" (click)="maatModal.hide()" aria-label="Close">
                    <i aria-hidden="true" class="fa fa-close">
                    </i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 text-center margin200">
                        <span>Sailpoint Kroatië is naast de ideale plek voor bareboat huren, ook dé plek
                            om een
                            volledige zeilreis te boeken. Door onze kennis, ervaring en liefde voor
                            Kroatië is Sailpoint
                            in staat een geheel verzorgde zeilvakantie voor u en uw medereizigers te
                            organiseren.
                            Vlucht, zeiljacht, havenplaatsen en schipper, alles kan worden geregeld. Op
                            die manier kunt
                            u optimaal genieten van een heerlijke vakantie!</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <h2>
                            Uw gegevens
                        </h2>
                        <form ngNoForm id="demo-form" action="https://api.formbucket.com/f/buk_bZUUVXHQsSq8NmRih9BZTBkN"
                            method="post" target="_blank">
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-address-card-o fa-fw"></i></span>
                                <input class="name border_grey" placeholder="Naam" type="text" name="naam" required />
                            </div>
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-envelope-o fa-fw"></i></span>
                                <input class="email border_grey" placeholder="Email" type="email" name="email"
                                    required />
                            </div>
                            <div class=" input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-phone fa-fw"></i></span>
                                <input placeholder="Telefoon" class="border_grey" type="text" name="telefoon"
                                    required />
                            </div>
                            <div class="input-group form-group" style="background-color: #f1f1f1;">
                                <span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>
                                <!--<material-datepicker class="date left" placeholder="Van" accentColor="#1bbc9b" dateFormat="DD-MM-YYYY" [(date)]="this.filter.start" (onSelect)="onSelectS($event)">-->
                                <!--</material-datepicker>-->
                                <!--<material-datepicker class="date right" placeholder="Tot" dateFormat="DD-MM-YYYY" [(date)]="this.filter.end" (onSelect)="onSelectE($event)">-->
                                <!--</material-datepicker>-->
                                <p-calendar class="date left" placeholder="Van" dateFormat="dd-mm-yy"
                                    [(ngModel)]="filter.start" [disabledDays]="[0,1,2,3,4,5]" [maxDate]="filter.end"
                                    (onSelect)="onSelectS($event)"></p-calendar>
                                <p-calendar class="date right" placeholder="Tot" dateFormat="dd-mm-yy"
                                    [(ngModel)]="filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="filter.start"
                                    (onSelect)="onSelectE($event)">
                                </p-calendar>
                            </div>
                            <div class="input-group form-group">
                                <span class="input-group-addon"><i class="fa fa-file-text-o fa-fw"></i></span>
                                <textarea placeholder="Beschrijf ons uw ideale zeilvakantie" class="border_grey"
                                    name="bericht" rows="5" required></textarea>
                            </div>
                            <div class="float_left width_percentage100 marginbottom10">
                                <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                            </div>
                            <div class="form-actions">
                                <button class="button-primary form-submit btn btn-default btn-primary" id="submit">
                                    Verstuur
                                </button>
                            </div>
                            <input type=" text " name="Vanaf " value='{{filter.start}}' style="display: none " />
                            <input type="text " name="Tot " value='{{filter.end}}' style="display: none " />
                            <input type="text " name="__bucket_trap__ " value=" " style="display: none " />
                        </form>
                    </div>
                    <div class="col-md-4 ">
                        <div class="focus bg_blue">
                            <div class="margin20">
                                <p class="white">
                                    “Ik deel graag mijn kennis en ervaring over zeilen in Kroatië met u
                                    in een
                                    persoonlijk adviesgesprek”
                                </p>
                            </div>
                        </div>
                        <div class="focus">
                            <div class="triangle marginleft20">
                            </div>
                            <div class="space30">
                            </div>
                            <div class="focus relative">
                                <img alt="" class="radius_circle absolute" width="65" height="65"
                                    src="../assets/plaats/alen.jpg" />
                                <div class="activity marginleft85">
                                    <a href="/over_ons">
                                        <h5>
                                            ALEN ČEHIĆ
                                        </h5>
                                    </a>
                                    <div class="space10">
                                    </div>
                                    <p>
                                        Sailpoint Kroatië
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>