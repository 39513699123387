<footer class="float_left">
    <div class="section main-footer">
        <div class="container">
            <div class="row">
                <div class="space50">
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 ">
                    <h2 class="white">
                        SAILPOINT
                    </h2>
                    <div class="content contact">
                        <ul>
                            <li *ngFor="let contact of contactinfo;">
                                <i class="fa fa-{{ contact.symbol }}">
                            </i> {{ contact.data }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 ">
                    <h2 class="white">
                        OVERIG
                    </h2>
                    <div class="content overig">
                        <ul>
                            <li *ngFor="let link of menu;">
                                <a routerLink="/{{ link.link }}" routerLinkActive="active">
                                {{ link.name }}
                            </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 ">
                    <h2 class="white">
                        PARTNERS
                    </h2>
                    <div class="content partners">
                        <div class="partners-left">
                            <img src="/assets/footerpartners/nausys.PNG" />
                            <img src="/assets/footerpartners/EMCI.jpg" />
                        </div>
                        <div class="partners-right">
                            <img src="http://res.cloudinary.com/dk3o7iytv/image/upload/c_lpad,h_125,w_125/v1523879223/logo_yacht_insurance_en_h2rc16.jpg" />
                            <img src="/assets/footerpartners/Kroatie.png" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-xs-12 ">
                    <h2 class="white">
                        NEEM CONTACT OP
                    </h2>
                    <div class="content contactform">
                        <form ngNoForm action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h" method="post" target="_blank">
                            <div class="form-group">
                                <input class="bg_greydark2 form-control" placeholder="NAAM" type="text" name="naam" ngModel required />
                            </div>
                            <div class="form-group">
                                <input type="email" class="bg_greydark2 form-control" placeholder="EMAIL" name="email" ngModel required/>
                            </div>
                            <div class="form-group">
                                <textarea type="text" class="bg_greydark2 form-control" placeholder="BERICHT" name="bericht" rows="5" ngModel required>
                            </textarea>
                            </div>
                            <div class="form-group float_left">
                                <re-captcha id="small-recap" site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn button-primary btn btn-default btn-primary">
                                Stuur
                            </button>
                            </div>
                            <input type="text" name="__bucket_trap__" value="" style="display: none" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section bottom-footer">
        <div class="container">
            <div class="padding10 left-stuff col-md-6 col-xs-12">
                <div class="space20">
                </div>
                Copyright Sailpoint © 2006 - {{ currentyear }}
            </div>
            <div class="padding10aligncenter_iphoneland aligncenter_iphonepotr right-stuff col-md-6 col-xs-12">
                <div class="youtube">
                    <a href="https://www.youtube.com/+ZeilbootKroatie" rel="nofollow" target="_blank" class="press right btn_icon bigger white ext">
                        <i class="fa fa-youtube">
                        </i>
                    </a>
                </div>
                <div class="facebook">
                    <a href="https://www.facebook.com/sailpointkroatie/" rel="nofollow" target="_blank" class="press right btn_icon facebook bigger white ext">
                        <i class="fa fa-facebook">
                        </i>
                    </a>
                </div>
                <div class="chevron">
                    <a href="#" rel="nofollow" class="zoom internal_link right btn_icon bg_greydark2 small radius white scrollToTop">
                        <i class="fa fa-chevron-up">
                        </i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
