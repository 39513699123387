<titleimage [title]="'Bedankt!'">
</titleimage>
<main class="container">
    <div class="row">
        <div class="col-md-8 col-xs-12">
            <h1 class="marginbottom10">Bedankt voor uw bericht</h1>
            <p>Wij zullen zo spoedig mogelijk contact met u opnemen om al uw vragen te beantwoorden.</p>
            <p>Kijkt u gerust verder op onze website of lees een van onze blogs.</p>
        </div>
        <div class="col-md-4 col-xs-12">
            <h1 class="marginbottom10">Verhalen over kroatië</h1>
            <div *ngFor="let blog of blogs" class="col-md-12 col-sm-6 col-xs-6 marginbottom20 padding0">
                <div class="archive2 border_grey float_left bg_greydark2 fadeinout">
                    <div class="focus overflow width_percentage40">
                        <a routerLink="{{ blog.url }}">
                            <img class="zoom_image" src='{{ blog.field_afbeeldingen[0].src | cd_b:"w_266,h_250,c_fill" }}' />
                        </a>
                        <div class="absolute_top">
                            <a routerLink="{{ blog.url }}" class="btn bg_darkblue left white medium">
                                        Bekijk
                                    </a>
                        </div>
                    </div>
                    <div class="section width_percentage60">
                        <div class="bg_darkblue section">
                            <a routerLink="{{ blog.url }}">
                                <h4 class="white margin20">
                                    {{ blog.title }}
                                </h4>
                            </a>
                        </div>
                        <div class="focus">
                            <div class=" focus padding0_right padding1015 sizing">
                                <p class="white">
                                    <i class="fa fa-calendar">
                                            </i> {{ blog?.post_date }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
