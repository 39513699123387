<front-slider>
</front-slider>

<!--
<front-bestdeals>
</front-bestdeals>
-->
<front-blogs>
</front-blogs>


<front-counter>
</front-counter>

<!--
<front-destinations>
</front-destinations>
-->

<!-- <front-references>
</front-references> -->

<!-- <front-keypoints>
</front-keypoints> -->

<front-partners>
</front-partners>

<front-boten>
</front-boten>