<iframe style="width:100%; margin-top:-15px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9755.715744374002!2d4.604647!3d52.317292!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd828482fb3c2f9c0!2sZeilboot+Kroati%C3%AB!5e0!3m2!1snl!2snl!4v1488401965277"
    width="1920" height="450" frameborder="0" allowfullscreen>
</iframe>
<div class="container">
    <div class="row">
        <div class="col-xs-12 col-sm-12 destoverlay">
            <div class="section border_grey bg_grey destinner">
                <div *ngFor="let l of contact;" class="col-md-4 padding0">
                    <div class="margin50 archive1">
                        <a target="" class="btn_icon {{ l.color }} extrabig square white absolute">
                            <i class="fa {{ l.icon }}">
                            </i>
                        </a>
                        <div class="float_left marginleft100">
                            <h4>
                                {{ l.title }}
                            </h4>
                            <div class="space20">
                            </div>
                            <p>
                                {{ l.subtitle1 }}
                                <br/> {{ l.subtitle2 }}
                            </p>
                            <div class="space20">
                            </div>
                            <div class="space5">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row ">
        <div class="col-xs-12 col-sm-6 margintop30">
            <div class="content contactform">
                <form ngNoForm action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h" method="post" target="_blank">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Naam" name="naam" ngModel required/>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Email" name="email" ngModel required/>
                    </div>
                    <div class="form-group">
                        <textarea type="text" class="form-control" placeholder="Bericht" name="bericht" rows="5" ngModel required>
                        </textarea>
                    </div>
                    <div class="form-group">
                        <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn bg_green button-primary btn btn-default btn-primary">
                            Stuur
                        </button>
                    </div>
                    <input type="text" name="__bucket_trap__" value="" style="display: none" />
                </form>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 margintop30">
            <div class="fb-page" data-href="https://www.facebook.com/sailpointkroatie/" data-tabs="timeline" data-small-header="true" data-adapt-container-width="true" data-width="500" data-hide-cover="false" data-show-facepile="true">
            </div>
        </div>
    </div>
</div>
