<section style="background: url(/assets/static-pages/frontpage/boeg.jpg) 50% 50% / cover;" class="section">
    <div class="filter greydark">
        <div class="container">
            <section style="margin:100px 0 !important;" class="section ">
                <div class="col-sm-3 col-xs-6 center">
                    <div class="center">
                        <p class="counter white bg_none extrasize subtitle" data-to="720" data-speed="2000">
                        </p>
                        <div class="space5">
                        </div>
                    </div>
                    <a routerLink="/boten" class=" btn bg_blue small square white">
                        BOTEN
                    </a>
                </div>
                <div class="col-sm-3 col-xs-6 center">
                    <div class="center">
                        <p class="counter white bg_none extrasize subtitle"  [attr.data-to]="experienceYears" data-speed="2000">
                        </p>
                        <div class="space5">
                        </div>
                    </div>
                    <a routerLink="/over_ons" class=" btn bg_green small square white">
                        JAAR ERVARING
                    </a>
                </div>
                <div class="col-sm-3 col-xs-6 center">
                    <div class="center">
                        <p class="counter white bg_none extrasize subtitle" data-to="62" data-speed="2000">
                        </p>
                        <div class="space5">
                        </div>
                    </div>
                    <a routerLink="/plaatsen" class=" btn bg_yellow small square white">
                        HAVENS
                    </a>
                </div>
                <div class="col-sm-3 col-xs-6 center">
                    <div class="center">
                        <p class="counter white bg_none extrasize subtitle" data-to="244" data-speed="2000">
                        </p>
                        <div class="space5">
                        </div>
                    </div>
                    <a routerLink="/blogs" class=" btn bg_orange small square white">
                        BLOGS
                    </a>
                </div>
            </section>
        </div>
    </div>
</section>
