<section style="margin-top:-15px" class="section marginbottom30">
    <div id="mapid" style="height:244px;">
    </div>
</section>
<section class="section marginbottom20">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 padding0 destoverlay">
                <div class="section row border_grey bg_grey destinner">
                    <div *ngFor="let l of locationtip;" class="col-md-4 padding0">
                        <div class="margin50 archive1">
                            <div class="btn_icon {{ l.color }} extrabig square white absolute">
                                <i class="fa {{ l.icon }}">
                                </i>
                            </div>
                            <div class="float_left marginleft100">
                                <h4>
                                    {{ l.title }}
                                </h4>
                                <div class="space20">
                                </div>
                                <p>
                                    {{ l.subtitle }}
                                </p>
                                <div class="space20">
                                </div>
                                <div class="space5">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="space20">
                    </div>
                    <h1 class="center">
                        Locaties
                    </h1>
                    <h3 class="center">
                        Een overzicht van de mooiste bestemmingen in Kroatië
                    </h3>
                    <div class="divider text-center big ">
                        <span style="background-color:#f1f1f1;">
                        </span>
                    </div>
                    <div class="space30">
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let plaats of locations" class="col-md-3 col-sm-6 col-xs-12 marginbottom20">
                    <div class="archive1 border_grey center">
                        <a routerLink="{{plaats.url}}">
                        <img class="" typeof="foaf:Image" src="{{ plaats.pictures[0].url | cd_b:'w_268,h_268,c_fill' }}" alt="{{ plaats.pictures[0].alt }}" />
                        <div class="textevidence bg_orange">
                            <h5 class="white margin20">
                                
                                    {{ plaats.title }}
                                
                            </h5>
                            <i class="fa fa-anchor iconbg orange">
                            </i>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="space20">
                    </div>
                    <h1 class="center">
                        Zeilroutes
                    </h1>
                    <div class="divider text-center big ">
                        <span style="background-color:#f1f1f1;">
                        </span>
                    </div>
                    <div class="space30">
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let route of routes" class="col-md-3 col-sm-6 col-xs-12 marginbottom20">
                    <div class="archive1 border_grey center">
                        <a routerLink="{{ route.url }}">
                        <div class="textevidence bg_greydark">
                            <h4 class="white margin200">
                                    Route vanuit {{ route.days[0].from }}
                            </h4>
                        </div>
                        <img class="" typeof="foaf:Image" src="{{ route.days[0].images[0].url | cd_b:'w_268,h_268,c_fill' }}" alt="{{ route.days[0].images[0].alt }}" />
                        <div class="textevidence bg_darkblue">
                            <h5 class="white margin200">
                                
                                    {{ route.title }}
                            </h5>
                            <i class="fa fa-anchor iconbg darkblue">
                            </i>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
