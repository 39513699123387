<section style="margin-top:-15px" class="section marginbottom30">
    <div id="mapid" style="height:244px;">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12" style="margin:-180px auto; z-index: 1000; text-shadow: 0px 0px 40px #3e3e3e;">
                <h1 class="center">
                    {{ route?.title }}
                </h1>
                <h2 class="center">
                    {{ route?.subtitle }}
                </h2>
                <div class="divider text-center big ">
                    <span style="background-color:#434a54;">
                        </span>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section marginbottom20">
    <div class="container">
        <div class="row">
            <div class="row">
                <div class="col-sm-12 col-sm-12 col-xs-12 marginbottom20">
                    <p class="text-center marginbottom20">{{ route?.text }}</p>
                    <p-accordion [multiple]="true">
                            <p-accordionTab *ngFor="let day of route?.days" header="{{ day?.title }}" [selected]="true">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <p *ngFor="let p of day?.paragraphs" class="marginbottom10">
                                            {{ p?.text }}
                                        </p>
                                    </div>
                                    <div class="col-sm-5">
                                        <a *ngFor="let image of day?.images" class="imageholder" href='{{ image?.url | cd_b:"w_1200,h_600,c_fit" }}' data-lightbox="custom" data-title="{{ image?.alt }}">
                                            <img width="200px" src='{{ image?.url | cd_b:"c_fill,w_200,h_200" }}' alt="{{ image?.alt }}">
                                        </a>
                                    </div>
                                </div>
                            </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col-sm-12 col-sm-12 col-xs-12 marginbottom20">
                    <p><b>LET OP!</b></p>
                    <p>Deze routebeschrijving is niet bedoeld als vervanging van ‘pilot-books’ of ‘marine-charts’. Sailpoint Kroatië is op geen enkele wijze aansprakelijk voor ongemakken of problemen ontstaan door het volgen vandeze of andere routebeschrijvingen.</p>
                </div>
            </div>
        </div>
    </div>
</section>
