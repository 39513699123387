<titleimage [title]="blog?.title">
</titleimage>
<div id="blog" class="container margintop20">
    <div class="row">
        <div class="col-md-8">
            <div class="archive1 padding010">
                <h1 class="subtitle greydark">
                    {{ blog?.title }}
                </h1>
                <div class="space20">
                </div>
                <div class="divider left small">
                    <span class="bg_darkblue">
                    </span>
                </div>
                <div class="space20">
                </div>
            </div>
            <section class="section" [innerHTML]="blog?.body">
            </section>
            <div class="space10">
            </div>
        </div>
        <div class="col-md-4">
            <div id="slider" class="slider flexslider">
                <ul class="slides">
                    <li *ngFor="let img of blog?.field_afbeeldingen">
                        <a href='{{ img.src | cd_b:"" }}' data-lightbox="custom">
                            <img src='{{ img.src | cd_b:"w_auto,h_500" }}' />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
