<titleimage [title]="'Flottielje Zeilen'">
</titleimage>
<section>
    <div class="container">
        <div class="row">
            <h1 class="center">
                Flottielje in Kroatië met Sailpoint – De Kroatië zeilvakantie specialist!
            </h1>
            <div class="space20"></div>
            <div class="divider text-center big ">
                <span style="background-color:#f1f1f1;">
                </span>
            </div>
            <div class="space10"></div>
            <h2 class='center'>Nieuw in 2019 – Vanuit Pula en Split</h2>
            <div class="space20"></div>
        </div>
        <div class="row">
            <div class="col-sm-8">

                <h1>Zeilen in Kroatië</h1>
                <div class="space10"></div>
                <p>Kroatië is één van de interessantste zeilgebieden om een zeilvakantie door te brengen. Kroatië heeft een kustlijn die 5800 kilometer lang is en 1185 eilanden rijk is. Velen daarvan zijn onbewoond en de natuur is daar nog als vele decennia
                    geleden, ongerept! Iedere baai is weer anders en ieder eiland kent z’n eigen bezienswaardigheden. Er valt dus heel wat te zien en te ontdekken tijdens een zeilvakantie in Kroatië. Of je nu komt voor de rust of juist voor een bruisend
                    nachtleven, een ieder komt aan z’n trekken! Zeilen in Kroatië zal een ervaring zijn om nooit te vergeten! Onze positieve ervaringen worden door velen gedeeld!</p>
                <p>Wij varen altijd met relatief nieuwe jachten, voorzien van alle luxe en comfort die je mag verwachten voor een relaxte zeilvakantie. Mocht het onverhoopt nodig zijn, bieden we uiteraard 24-uurs technische support.</p>

                <h1>Waarom flottielje zeilen?</h1>
                <div class="space10"></div>
                <p>Wil je – net als velen die je inmiddels zijn voorgegaan – ook de mooie Adriatische zee langs de kust van Kroatië ontdekken? Maar heb je wellicht niet de ervaring van zee-zeilen of zoek je aansluiting bij andere zeilers of gezinnen? Dan
                    is flottielje zeilen uitermate geschikt voor jou! Op deze manier kun je met een gerust gevoel aan boord stappen van je eigen zeilboot en, wanneer jij dat wil, gebruik maken van de kennis en kunde van onze schipper en de gezelligheid
                    van andere flottielje zeilers.</p>
                <p>Wel ervaring en wil je je eigen koers varen? Geen probleem! We bespreken dagelijks de mogelijke routes etc, en zien je dan 's avonds wel weer op de afgesproken plaats.</p>
                <p>Op het begeleidend schip vaart een ervaren Nederlandse schipper mee. Hij/zij helpt je met alle vragen die je hebt, verzorgt dagelijks een uitgebreide briefing, geeft informatie over veiligheid, weersomstandigheden, vaarroutes en uiteraard
                    tips over de mooiste ankerplaatsen etc. </p>
                <p>Afhankelijk van het weer en de wensen van onze klanten stemmen we iedere ochtend af hoe groot de afstand is die we die dag willen afleggen. We zullen altijd genoeg tijd inruimen om voor anker te gaan in mooie baaien om te zwemmen, snorkelen
                    etc. Bij goed weer zullen we iedere avond een andere plaats voor de overnachting aandoen. Hierbij wisselen we aanmeren in een jachthaven en voor anker gaan in een baai af. ’s Avonds komen we bij elkaar en kun je ervoor kiezen om gezamenlijk
                    te eten of je eigen weg te gaan.</p>

                <h1>Wanneer</h1>
                <div class="space10"></div>
                <p>Vanaf zaterdag 29 juni organiseren wij onze flottieljes iedere week vanuit Pula en Split. Inchecken is zaterdag v.a. 16.00 uur, vertrek eerste zeildag is zondag. Terugkomst in de haven is op de vrijdag avond rond 18.00 uur. Uitchecken
                    is zaterdagochtend om 09.00 uur. </p>

                <h1>Meezeilen met het begeleidend schip – vanuit Pula</h1>
                <div class="space10"></div>
                <p>Ben je alleen of met een kleine groep en heb je geen vaarbewijs of niet voldoende ervaring om zelf een zeilboot te varen? Dan kun je kiezen om een cabine op de begeleidende zeilboot te boeken. Je kunt op deze manier leren zeilen van de
                    schipper die je alle kneepjes van zeezeilen bij kan brengen! </p>
                Wanneer: Alle zaterdagen zijn er enkele cabines beschikbaar op de boot van de flottielje begeleider. Kosten: € 850,- euro pp/pw inclusief schipper.

                <h1>Zeilklimaat in Kroatië in de zomermaanden</h1>
                <div class="space10"></div>
                <p>Kroatië heeft een mild (zeil)klimaat. In de zomer is het gemiddeld 26 - 30 graden. Meestal waait de Meastral, met windkracht 3 tot 4 Beaufort. In mei, juni en september waait het vaak iets harder, de zomermaanden juli en augustus meestal
                    iets minder, ideaal voor flottielje-zeilen! Uiteraard wordt er dagelijks de weersverwachting gebriefd en zal de route soms worden aangepast aan de weersomstandigheden. Veiligheid staat te allen tijde voorop!</p>
                <p>Voor meer informatie over flottielje zeilen, neem contact op met Alen Cehic, Sailpoint Kroatië, tel +31642143783 of via e-mail <a href="mailto:Info@Sailpoint.nl">Info@Sailpoint.nl</a></p>

                <div id="slider" class="slider flexslider">
                    <ul class="slides">
                        <li *ngFor="let img of sliderpics">
                            <a href='{{ img | cd_b:"" }}' data-lightbox="custom">
                            <img src='{{ img | cd_b:"w_auto,h_800" }}' />
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="row dests">
                    <div class="col-sm-6">
                        <a pageScroll href="#locations">
                            <div class="widget clearfix">
                                <div class="focus relative fadeinout overflow">
                                    <img src="{{ 'zb/plaatsen/pula' | cd_b:'w_300,h_300,c_fill' }}" class="focus zoom_image" />
                                    <div class="fadein filter greydark absolute height100percentage width_percentage100">
                                        <div class="absolute display_table height100percentage width_percentage100">
                                            <div class="cell vertical_middle">
                                                <a class="btn border_white white medium">MEER INFO</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2>Flottielje Pula</h2>
                            </div>
                        </a>
                    </div>
                    <div class="col-sm-6">
                        <a pageScroll href="#locations">
                            <div class="widget clearfix">
                                <div class="focus relative fadeinout overflow">
                                    <img src="{{ 'zb/plaatsen/split1' | cd_b:'w_300,h_300,c_fill' }}" class="focus zoom_image" />
                                    <div class="fadein filter greydark absolute height100percentage width_percentage100">
                                        <div class="absolute display_table height100percentage width_percentage100">
                                            <div class="cell vertical_middle">
                                                <a class="btn border_white white medium">MEER INFO</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2>Flottielje Split</h2>
                            </div>
                        </a>
                    </div>
                </div>
                <!--<div class="widget clearfix">-->
                <!--    <h2>Beleef Kroatie met Sailpoint & Katarina Cruises</h2>-->
                <!--    <iframe width="358" height="200" src="https://www.youtube.com/embed/qHz5I2VQ0bc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <!--</div>-->
                <div class="widget clearfix">
                    <h2>Flottielje zeilen met Sailpoint</h2>
                    <ul>
                        <li>Wanneer: Wekelijks vanaf 29 juni 2019</li>
                        <li>Vanuit: Pula & Split</li>
                        <li>Prijs: Vraag vrijblijvend een offerte aan</li>
                    </ul>
                </div>

                <div class="widget clearfix">
                    <h2>Contact & Vrijblijvende Offerte</h2>
                    <form ngNoForm action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h" method="post" target="_blank">
                        <input type=" text " name="Aanvraag Type: " style="display: none " value='Offerte Flottielje' />
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Naam" name="naam" ngModel required/>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" name="email" ngModel required/>
                        </div>
                        <div class="input-group form-group widget-date">
                            <!--<span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>-->
                            <p-calendar class="left" placeholder="Van" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.start" [disabledDays]="[0,1,2,3,4,5]" [minDate]="minDate" [maxDate]="this.filter.end" (onSelect)="onSelectS($event)"></p-calendar>
                            <p-calendar class="right" placeholder="Tot" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="this.filter.start" (onSelect)="onSelectE($event)">
                            </p-calendar>
                        </div>

                        <div class="form-group">
                            <textarea type="text" class="form-control" placeholder="Bericht" name="bericht" rows="5" ngModel required>
                            </textarea>
                        </div>
                        <input type=" text " name="Vanaf " style="display: none " value='{{filter.start}}' />
                        <input type="text " name="Tot " style="display: none " value='{{filter.end}}' />
                        <div class="form-group">
                            <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn bg_green button-primary btn btn-default btn-primary">
                            Stuur
                        </button>
                        </div>
                        <input type="text" name="__bucket_trap__" value="" style="display: none" />
                    </form>
                </div>

            </div>
        </div>
        <div class="row" id=locations>
            <h1 class="center">
                Flottielje Vertrekpunten
            </h1>
            <div class="space20"></div>
            <div class="divider text-center big ">
                <span style="background-color:#f1f1f1;">
                </span>
            </div>
            <div class="space10"></div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="archive2 border_grey">
                    <img src="{{ 'zb/plaatsen/pula' | cd_b:'w_700,h_300,c_fill' }}" />
                    <div class="bg_darkblue section">
                        <h4 class="white margin20">Pula</h4>
                    </div>
                    <div class="margin20">
                        <p>Vanuit Marina Veruda, Pula, ben ik zelf de flottielje begeleider. Zoals ik op mijn site schrijf, heb ik als doel u een onvergetelijke zeilvakantie in Kroatië te bezorgen, dat maak ik graag waar!</p>
                        <p>Geboren in Pula en opgegroeid in Rovinj, waar ik als jong jongetje al mijn eerste zeilwedstrijden heb gevaren. Jaar in jaar uit heb ik langs de Kroatische kust gezeild, van Rovinj tot Dubrovnik, als recreant en als sportief wedstrijdzeiler.
                            Ik ken alle leuke plaatsen, mooie baaien, vele goede restaurants en kan advies geven over gerechten, wijnen, lokale gewoontes etc. Ik ben dan ook als geen andere flottielje begeleider bekend met het gebied.</p>
                        <p>Sinds 1990 woon ik in Nederland waar ik eveneens in de zeilwereld mijn sporen heb verdiend. Uiteraard spreek ik beide talen vloeiend!</p>

                    </div>
                    <div class="bg_greydark section" style="position: relative; overflow: hidden;">
                        <h4 class="white margin20"> Mogelijke routes vanuit Pula:</h4>
                        <div class=" focus padding0_right padding020 sizing">
                            <p class="white">
                                <b>Kvarner route:</b> Marina Veruda – Mali Losinj – Unije – Rab - Susak – Ilovik – Pomer - Veruda
                            </p>
                            <p class="white">
                                <b>Istra route:</b> Marina Veruda – Brioni eilanden – Rovinj – Lim Fjord – Vrsar – Porec – Pula
                            </p>
                        </div>
                        <i class="fa fa-map-signs iconbg darkblue">
                            </i>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="archive2 border_grey">
                    <img src="{{ 'zb/plaatsen/split1' | cd_b:'w_700,h_300,c_fill' }}" />
                    <div class="bg_darkblue section">
                        <h4 class="white margin20">Split</h4>
                    </div>
                    <div class="margin20">
                        <p>Wanneer u flottielje gaat varen vanuit Split, kunt u uw boot boeken vanuit verschillende havens. Inchecken is op zaterdag vanaf 16.00 uur, de flottielje boten komen zondag bijeen op een afgesproken plaats en tijd. </p>

                    </div>
                    <div class="bg_greydark section" style="position: relative; overflow: hidden;">
                        <h4 class="white margin20"> Mogelijke routes vanuit split:</h4>
                        <div class=" focus padding0_right padding020 sizing">
                            <p class="white">
                                Maslinica - Milna - Vis - Hvar - Korcula – Lastovo - Stari Grad
                            </p>
                        </div>
                        <i class="fa fa-map-signs iconbg darkblue">
                            </i>
                    </div>
                </div>
                <h4 class="white margin20">
                    Split
                </h4>
            </div>
        </div>
    </div>
</section>
