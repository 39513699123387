<section
  class="section marginbottom30"
  [ngStyle]="{
    background:
      'url(../../assets/static-pages/tng-challenge-cup/banner.png) 50% 89% / cover'
  }"
  style="margin-top: -15px;"
>
  <div class="filter greydark">
    <div class="container clearfix">
      <div class="space90"></div>
      <h1 class="center white subtitle">
        TNG Challenge Cup
      </h1>
      <div class="space30"></div>
      <div class="divider center big">
        <span class="bg_white"> </span>
      </div>
      <div class="space90"></div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-sm-8">
        <h1>JOIN THE 2020 EDITION OF TNG CHALLENGE CUP</h1>
        <div class="space20"></div>
        <a
          class="white btn bg_green padding1020 medium"
          target="_blank"
          href="../../assets/TNG_ChallengeCup_Info2020.pdf"
          type="button"
        >
          Download the Brochure
        </a>
        <div class="space10"></div>
        <h2>SERIOUS RACING UNDER THE FINEST CONDITIONS</h2>
        <div class="space10"></div>
        <div class="space10"></div>

        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>

        <p>
          From October 3-10 of 2020, the spectacular TNG Challenge Cup will
          again be held in Croatia, one of the best sailing spots in the
          Mediterranean! This regatta will be divided into two classes, a ‘one
          design Racing class’ with First 45 boats with Spinnaker and a ‘Cruiser
          class’, without spinnaker. This Cruiser class has boats available such
          as Hanse 455 and More 55, both designed for competitive sailors, who
          like to challenge themselves - and others - during the day and enjoy
          having dinner in good (sailors) company at night.
        </p>

        <p>
          Join the fleet and you will have a full week (Saturday to Friday) of
          unforgettable sailing, beautiful sites and lots of fun-experiences.
          All boats are ready for regatta conditions and are fully equipped. The
          First 45 has full battened tri radial cut main sails and top spinnaker
          equipment, the perfect raceboat for true speedlovers! More into
          recreational sailing? The luxurious More 55 and powerfull Hanse 455
          both have full battened main sails and genoas that gives excellent
          racing performances as well as peacefull sailing. There will be a
          full-service 4-Day Race-program with three exciting and challenging
          adventurous boat-to-boat and navigation races a day*.
          <b
            ><i
              >You bring your sailing skills and we will take care of everything
              else!</i
            ></b
          >
        </p>

        <h2>FINEST CONDITIONS</h2>
        <div class="space10"></div>
        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>
        <p>
          The TNG Challenge Cup begins in Split, where you will pick-up your
          boat and sail to the island of Solta. The official Race-program starts
          on Solta, Maslenica and it will take you to the islands Brac, Hvar,
          Sveti Klement and Vis. You will enjoy the finest conditions of sailing
          in the famous waters and spinning winds of Pakleni otoci (also known
          as Hell Islands). Besides racing, there is time to enjoy the beautiful
          weather and amazing coastlines of Croatia. You will be mooring in
          exclusive and breath-taking locations, where tender-service,
          outstanding catering and sailing entertainment will be at your
          service!
        </p>

        <h2>SERIOUS PRIZE-GIVING</h2>
        <div class="space10"></div>
        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>
        <p>
          Winning the TNG Challenge Cup means you can bring home a stunning
          Overall Winner Trophy, as well as an exclusive TNG Swiss Watches
          sailingtimer for the whole crew. For the daily races, there will be a
          daily prize-giving, including some of the finest TNG Swiss Watches;
          Dutch design, Swiss made perfection!
        </p>

        <h2>DAY BY DAY TRAVEL PROGRAM</h2>
        <i
          >Depending on the weather conditions, the program will be as
          follows:</i
        >
        <div class="space10"></div>
        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>
        <h3>Saturday</h3>
        <p>
          The adventure begins in Split, Croatia. Once you have arrived at the
          airport on Saturday the 5th, you will travel to Marina Kaštela
          (approx. 11 km from Split airport) where one of our TNG Challenge Cup
          crew-members will welcome you and will take you to your boat, that
          will be ready as from 15.00 to (at the latest) 17.00 hours. There is a
          supermarket at this full-service Marina to provide your boat with
          everything you need. And we will be happy to help you getting it all
          aboard!
        </p>
        <p>
          We suggest you sail out of Marina Kaštela at the end of the day and
          set course to the NorthEast side of the island Drvenik Veli, where you
          can anchor for the night in the bay of Drvenik Veli.
        </p>

        <h3>Sunday</h3>
        <p>
          The next day you are expected at the Martinis Marchi Marina on the
          Island Solta, but not before 17.00 hrs. This gives you the opportunity
          to explore the breath-taking Blue Lagoon (Krknjaši Lagoon), located at
          the west-side of the island Drvenik Veli. It is the perfect place for
          swimming and snorkelling.
        </p>
        <p>
          Set sail to the island Solta (approx. 2,5 NM of sailing from the Blue
          Lagoon) after lunch, to arrive on time at the Martinis Marchi Marina,
          where we have reserved a mooring place for you. It’s only a few steps
          to the Martinis Marchi Heritage Hotel, where we welcome you to meet
          all TNG Challenge Cup competitors for the official Opening Ceremony
          and to have a festive dinner. The whole fleet stays overnight at the
          luxury Marchi Marina.
        </p>

        <h3>Monday - First racing day of the TNG Challenge Cup!</h3>

        <p>
          After breakfast the fleet will start the First Navigation Race from
          Solta to Hvar (approx. 14 NM). In the afternoon we will set course to
          Stari Grad, Hvar. In the bay of Stari Grad we will have a boat-to-boat
          race with plenty of action! When the races are finished, the fleet
          enters the harbour of Stari Grad where we will have a local
          wine-tasting and dinner.
        </p>

        <h3>Tuesday - Second racing day of the TNG Challenge Cup!</h3>

        <p>
          After breakfast the fleet sets course to Palmizana on the Island Sveti
          Klement (Pakleni Otoci), for a navigation race of approx. 10 NM. You
          will sail along the shore of Island Hvar, known of many wind-shiftings
          in which your sailing and trimming skills will be challenged to the
          max! The finish will be in between the Island of Hvar and Sveti
          Klement. After lunch it’s ‘Game On’, as the west-coast waters of Sveti
          Klement are promising some great sailing during the Second
          Boat-to-Boat Course Racing competition!
        </p>

        <p>
          At the end of this competitive day, we will moore at ACI Marina
          Palmizana, one of the most beautiful and natural Marina’s in the
          Adriatic! Once settled, there will be a daily Prize-giving and drinks
          for Happy-Hour. Dinner is organised in the high-quality restaurant
          Meneghello located a few minutes’ walk from the marina. After dinner,
          if you like to, a fast ferry can take you to Hvar, where there are
          possibilities to visit the old city centre and experience night life.
          Expect to be amazed!
        </p>

        <h3>Wednesday - Third racing day of the TNG Challenge Cup!</h3>

        <p>
          After breakfast, the racing day starts with a boat-to-boat race on the
          west side of Sveti Klement in front of the bay of Vinogradisce. After
          this short but dynamic coastal race, we will, in the afternoon,
          proceed with a navigation race to Stoncica bay on the Island Vis. From
          here we will enter the harbour of Vis-town to spend the night.
        </p>

        <h3>Thursday - Final racing day of the TNG Challenge Cup!</h3>

        <p>
          After breakfast in Vis we will set sail for the final navigation race
          to Milna, on the Island of Brac, approx. 20 NM. In the evening we will
          have the prize giving ceremony. The overall-winner boat will receive
          the prestigious TNG Trophy and for the entire crew of the winning boat
          there will be a luxurious TNG- sailing watch.
        </p>

        <h3>Friday</h3>
        <p>
          Friday is a day for you to spend as you wish. Whatever your plans will
          be, we will be happy to give you the best insiders tips!
        </p>
        <p>
          You are expected to return the boat at the Marina Kaštela near Split
          on Friday, no later than 18.00 hours. Have fun, be safe and we will
          see you there!
        </p>

        <h2>SERVICE</h2>
        <div class="space10"></div>
        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>
        <p>
          Participation in the TNG Challenge Cup 2019 includes: full use (Sat.
          to Fri.) of a One Design First 45 race boat ‘Cabin-ready’ incl.
          Spinnaker, Four Day Race Program*, Hospitality Program and Catering
          (3-course dinner including limited drinks on racing-days).
        </p>

        <h2>BOOKING & PRICES</h2>
        <div class="space10"></div>
        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>
        <p>
          <b>You can book the TNG-Cup as an entire crew or as an individual.</b
          ><br />
          <b>One Design First 45 race boat - as crew</b> min. 6 / max. 8
          persons: € 2.590 per boat <br />
          Regatta fee € 1.500 per boat - Social program € 750 per person<br />
          <b>More 55 Cruising class boat - as crew </b> min. 6 / max. 12
          persons: € 4.100<br />
          Regatta fee € 1.750 per boat - Social program € 750 per person<br />
          <b>Hanse 455 Cruising class boat - as crew </b> min. 6 / max. 10
          persons: € 2.200<br />
          Regatta fee € 1.600 per boat - Social program € 750 per person<br />
        </p>

        <p>
          Not included in the price: Flight to and from Split Airport and
          transfer to boat pick-up location (est.16 min. / € 40 for 6 persons),
          Mooring costs on non-racing days, lunches, breakfast and dinner on
          non-racing days, transit log, end cleaning and tourist tax.<br />
          <i>*Race Program subject to change due to weather conditions</i>
        </p>

        <h2>ENTER NOW</h2>
        <div class="space10"></div>
        <div class="divider left small">
          <span class="bg_lightgrey"></span>
        </div>
        <div class="space10"></div>
        <p>
          The TNG Challenge Cup is organized by TNG Swiss Watches in cooperation
          with Sailpoint Croatia, a Dutch based company with over thirty years’
          experience in Croatian Sailing and racing events. Sailpoint Croatia
          has personally tested every location and guaranties a top-class boat
          and outstanding gear. In fact, the only thing missing on the boat is
          you, the adventurous sailor! <br />
          <b>Join today, as the fleet is limited to max 20 yachts! </b>
        </p>
      </div>
      <div class="col-sm-4">
        <div class="widget clearfix">
          <h2>8 REASONS TO SIGN UP FOR THE TNG CHALLENGE CUP</h2>
          <ul>
            <li>A fleet of full-equipped One Design First 45 racing boats</li>
            <li>A fleet of More 55 and Hanse 455 Cruising boats</li>
            <li>
              Full week (Saturday to Friday) use of your own high-end boat
            </li>
            <li>
              4-Day Race-program of adventurous boat-to-boat and navigation
              racing*
            </li>
            <li>Two exciting and challenging races a day</li>
            <li>Mooring in exclusive and breath-taking locations</li>
            <li>Full-service race program* and prize-giving</li>
            <li>
              Outstanding catering with fresh breakfast, lunch, happy hour and
              dinner
            </li>
          </ul>
        </div>

        <div class="widget clearfix">
          <h2>CONTACT & SIGNING UP</h2>
          <form
            ngNoForm
            action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h"
            method="post"
            target="_blank"
          >
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Naam"
                name="naam"
                ngModel
                required
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                name="email"
                ngModel
                required
              />
            </div>
            <div class="form-group">
              <textarea
                type="text"
                class="form-control"
                placeholder="Bericht"
                name="bericht"
                rows="5"
                ngModel
                required
              >
              </textarea>
            </div>
            <div class="form-group">
              <re-captcha
                site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"
              ></re-captcha>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn bg_green button-primary btn btn-default btn-primary"
              >
                Stuur
              </button>
            </div>
            <input
              type="text"
              name="__bucket_trap__"
              value=""
              style="display: none"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
