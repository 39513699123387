<section class="section margintop20 marginbottom20">
    <div class="container">
        <h1>
            Verhalen uit Kroatië
        </h1>
        <div class="divider text-center big ">
            <span style="background-color:#f1f1f1;">
            </span>
        </div>
        <div class="space30"></div>
        <div class="row">

            <div *ngFor="let blog of blogs" class="col-md-3 col-sm-6 col-xs-6 marginbottom20 padding0_right">
                <div class="archive2 border_grey">
                    <div class="focus overflow">
                        <a routerLink="{{ blog.url }}">
                            <img src='{{ blog.field_afbeeldingen[0].src | cd_b:"w_266,h_250,c_fill" }}' />
                        </a>
                        <div class="absolute_top">
                            <a routerLink="{{ blog.url }}" class="btn bg_darkblue left white medium">
                                        Bekijk
                                    </a>
                        </div>
                    </div>
                    <div class="bg_darkblue section" style="height: 74px;">
                        <h4 class="white margin20">
                            {{ blog.title }}
                        </h4>
                    </div>
                    <div class="focus bg_greydark2">
                        <div class=" focus padding0_right padding1015 sizing">
                            <p class="white">
                                <i class="fa fa-calendar">
                                        </i> {{ blog?.post_date }}
                            </p>
                        </div>
                    </div>
                    <div class="margin20 displaynone_iphonepotr" style="height:120px;">
                        <p>
                            {{ blog.subtitle }}
                        </p>
                        <div class="space20">
                        </div>
                        <a routerLink="{{ blog.url }}" class="white bg_darkblue btn border_grey medium press">
                                    BEKIJK
                                </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
