<titleimage [title]="'Over'">
</titleimage>
<main class="container">
    <div class="col-md-4 col-xs-12">
        <img src="/assets/static-pages/over/alen.jpg" />
    </div>
    <div class="col-md-8 col-xs-12">
        <p>
            Mijn naam is Alen Čehić.
            <br/> Met mijn bedrijf Sailpoint Kroatië heb ik als doel u een onvergetelijke zeilvakantie in Kroatië te bezorgen!
        </p>
        <p>
            Een doel uitspreken is niet zo moeilijk, het waarmaken, daar gaat het om!
            <br/> Ik ben er van overtuigd dat door mijn vele jaren ervaring, brede kennis van de zeilsport, van Kroatië en de Adriatische zee, Sailpoint Kroatië de aanbieder van verhuur van zeil- en motorboten in Kroatië is en dat ik u een optimale en
            onbezorgde zeilvakantie kan bieden.
        </p>
        <p>
            Voor vertrek heb ik persoonlijk contact met u over het zeilgebied, interessante baaien, stadjes en zelfs goede restaurants. Maar ook over weersomstandigheden, navigatie, belangrijke sites en overige zaken waar u rekening mee dient te houden.
            <br/> Door dit persoonlijke contact, de transparantie over onze diensten en producten, mijn uitgebreide netwerk in Kroatië en de nauwe samenwerking met de lokale charterbedrijven, realiseer ik ons doel, maar belangrijker, uw wensen!
            <br/> Yachting en zeilen is onze passie en way-of-life en dat deel ik graag met u!
            <br/>
            <b>
                <i>
                    “Navigare necesse est"               </i> </b> </p>
        <p> Ik werk met charterbedrijven in Kroatië die ik persoonlijk ken en die voldoen aan alle kwaliteitseisen die ik stel op dit gebied, zoals membership of Yachtpool en 4Star Quality. In onze thuisbases Rovinj en Pula (Marina Veruda) hebben wij met
            Waypoint International een eigen vloot liggen. </p>
        <p> Vanaf 1985 ben ik met Waypoint International nauw betrokken in de charter- en brokerage ontwikkelingen in Kroatië. Wij zijn gecertificeerd Yacht Broker door maritiem instituut EMCI en we zijn aangesloten bij Sea Independent Croatia, wereldwijd
            broker in kwaliteitsjachten. <a href="http://www.seaindependent.hr" target="_blank">
                    www.seaindependent.hr
                </a>
        </p>
    </div>
</main>
