<titleimage [title]="'Blogs'">
</titleimage>
<section class="section margintop20 marginbottom20">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <input class="search" placeholder="onderwerp" [(ngModel)]="filter.topic" (keyup)="search()" />
            </div>
            <div class="col-md-12" infinite-scroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="300" (scrolled)="onScroll()">
                <div *ngIf="blogs?.length == 0">
                    <i>
                            Er zijn helaas geen blogs gevonden met deze eigenschappen
                        </i>
                </div>
                <div *ngFor="let blog of blogs" class="col-md-3 col-sm-6 col-xs-6 marginbottom20 padding0_right">
                    <div class="archive2 border_grey">
                        <div class="focus overflow">
                            <a routerLink="{{ blog.url }}">
                                <img src='{{ blog.field_afbeeldingen[0].src | cd_b:"w_268,h_268,c_fill" }}' />
                            </a>
                            <div class="absolute_top">
                                <a routerLink="{{ blog.url }}" class="btn bg_darkblue left white medium">
                                        Bekijk
                                    </a>
                            </div>
                        </div>
                        <div class="bg_darkblue section" style="height: 74px;">
                            <h4 class="white margin20">
                                {{ blog.title }}
                            </h4>
                        </div>
                        <div class="focus bg_greydark2">
                            <div class=" focus padding0_right padding1015 sizing">
                                <p class="white">
                                    <i class="fa fa-calendar">
                                        </i> {{ blog?.post_date }}
                                </p>
                            </div>
                        </div>
                        <div class="margin20 displaynone_iphonepotr" style="height:120px;">
                            <p>
                                {{ blog.subtitle }}
                            </p>
                            <div class="space20">
                            </div>
                            <a routerLink="{{ blog.url }}" class="white bg_darkblue btn border_grey medium press">
                                    BEKIJK
                                </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
