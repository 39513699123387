<titleimage [title]="'Special Boats'">
</titleimage>

<section>
    <div class="container">
        <div class='row'>
            <h1 class='center'>
                Performance Boats
            </h1>
            <div class="space20"></div>
            <div class="divider text-center big ">
                <span style="background-color:#f1f1f1;">
                </span>
            </div>
            <div class="space10"></div>
            <h2 class='center'> Superieur zeil-plezier in Kroatië</h2>
            <div class='space20'></div>
            <p class='center width_percentage80' style="margin: 0 auto">Wil je superieur zeil-plezier in Kroatië met een groter, luxer zeiljacht, met nog betere zeil- eigenschappen, meer comfort en ruimte? Naast ons standaard aanbod op de site, bieden wij een aantal nieuwe, zeer mooie, bijzondere boten aan waarmee jouw vakantie een nog onvergetelijkere ervaring kan worden. Een aantal van deze Special Boats zijn ‘bareboat’ te huur, anderen alleen met schipper. Uiteraard kun je, indien gewenst, iedere boot ook met een schipper, of zelfs met hostess en/of kok, huren.</p>
            <div class='space20'></div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <h1>Performance Boats</h1>
                <div class="space10"></div>
                <p>De sportieve zeiler is bij Sailpoint aan het goede adres!</p>
                
                <h2>First 35 en First 45</h2>
                <p>Wij hebben een ruim aanbod First 35 en First 45 boten te huur. De First 35 en 45 boten staan bekend om hun goede zeilprestaties. De up-wind performance is uitstekend, goede trimmogelijkheden, de boot zeilt perfect en is volledig in balans. Wilt u ook goede down-wind performance? Huur een spinnaker erbij! Zeilen in een First geeft voldoening!</p>
                <p>Uiteraard zijn deze First boten allemaal volledig equipped en voorzien van alle luxe en comfort voor een relaxte cruise vakantie. De moderne vloot van First 35 en First 45 boten ligt in Marina Kastela, Split. Tevens hebben we een aantal First 35 boten in Marina Jezera op het eiland Murter liggen.</p>
                <h2>X-Yachts</h2>
                <p>Nu ook mogelijk: Charter met een X-Yacht in de Adriatic! Wij hebben een X4ᵌ beschikbaar vanuit Split. Deze 3-cabin boot is fully equipped en heeft een uitstekende performance. </p>
                <h2>More 55</h2>
                <p>Ook in {{ currentyear }} hebben we een aantal More 55 yachten beschikbaar vanuit Marina Kastela, Split. Bij More boten vinden luxe en performance elkaar in goede harmonie.</p>
                <p>Voor meer informatie over deze bijzondere Special Boats, neem contact op met Alen Cehic, Sailpoint Kroatië, tel +31642143783 of via e-mail <a href="mailto:Info@Sailpoint.nl">Info@Sailpoint.nl</a></p>
                            <div id="slider" class="slider flexslider">
                    <ul class="slides">
                        <li *ngFor="let img of sliderpics">
                            <a href='{{ img | cd_b:"" }}' data-lightbox="custom">
                            <img src='{{ img | cd_b:"w_auto,h_800" }}' />
                        </a>
                        </li>
                    </ul>
                </div>
            
            </div>
            <div class="col-sm-4">
                <!--<div class="widget clearfix">-->
                <!--    <h2>Beleef Kroatie met Sailpoint & Katarina Cruises</h2>-->
                <!--    <iframe width="358" height="200" src="https://www.youtube.com/embed/qHz5I2VQ0bc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <!--</div>-->

                <div class="widget clearfix">
                    <h2>Contact & Vrijblijvende Offerte</h2>
                    <form ngNoForm action="https://api.formbucket.com/f/buk_4E1UQEzsh4dUgdM7AaGFyG4h" method="post" target="_blank">
                        <input type=" text " name="Aanvraag Type: " style="display: none " value='Offerte Special Boats' />
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Naam" name="naam" ngModel required/>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" name="email" ngModel required/>
                        </div>
                        <div class="input-group form-group widget-date">
                            <!--<span class="input-group-addon"><i class="fa fa-calendar-o fa-fw"></i></span>-->
                            <p-calendar class="left" placeholder="Van" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.start" [disabledDays]="[0,1,2,3,4,5]" [maxDate]="this.filter.end" (onSelect)="onSelectS($event)"></p-calendar>
                            <p-calendar class="right" placeholder="Tot" dateFormat="dd-mm-yy" [(ngModel)]="this.filter.end" [disabledDays]="[0,1,2,3,4,5]" [minDate]="this.filter.start" (onSelect)="onSelectE($event)">
                            </p-calendar>
                        </div>
     
                        <div class="form-group">
                            <textarea type="text" class="form-control" placeholder="Bericht" name="bericht" rows="5" ngModel required>
                            </textarea>
                        </div>
                        <input type=" text " name="Vanaf " style="display: none " value='{{filter.start}}' />
                        <input type="text " name="Tot " style="display: none " value='{{filter.end}}' />
                        <div class="form-group">
                            <re-captcha site_key="6LcsfiEUAAAAAIOmlb8hlZuTscA4A3ub4umeDhVx"></re-captcha>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn bg_green button-primary btn btn-default btn-primary">
                            Stuur
                        </button>
                        </div>
                        <input type="text" name="__bucket_trap__" value="" style="display: none" />
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>
